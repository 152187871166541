import React from 'react';
import './Belt.scss';

export default ({
  beltColor,
}) => (
  <span className="belt-container">
    <span className={`belt ${beltColor}`}>
      <span className="decoration" />
    </span>
  </span>
);

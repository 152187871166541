import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'src/components/UI/Container';
import Instructor from 'src/components/instructor/Instructor';
import { Helmet } from "react-helmet"
import Header from 'src/components/header/Header';
import Footer from 'src/components/footer/Footer';
import './instructors.scss';

const query = graphql`
  query {
    allInstructorsJson {
      edges {
        node {
          id
          firstname
          description
          photo
          title
          type
          lastname
          belt_color
          order
        }
      }
    }
    advancedText: allWebsiteTextsJson(filter: {identifier: {eq: "advanced-instructors"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
    basicsText: allWebsiteTextsJson(filter: {identifier: {eq: "basics-instructors"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
    kidsText: allWebsiteTextsJson(filter: {identifier: {eq: "kids-instructors"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
    noGiText: allWebsiteTextsJson(filter: {identifier: {eq: "no-gi-instructors"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
    wrestlingText: allWebsiteTextsJson(filter: {identifier: {eq: "wrestling-instructors"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
  }
`;

const BELT_VALUE_MAPPING = {
  white: 0,
  blue: 1,
  purple: 2,
  brown: 3,
  black: 4,
};

export default () => {
  const {
    allInstructorsJson: { edges },
    advancedText: { nodes: [advancedText] },
    basicsText: { nodes: [basicsText] },
    kidsText: { nodes: [kidsText] },
    noGiText: { nodes: [noGiText] },
    wrestlingText: { nodes: [wrestlingText] },
  } = useStaticQuery(query);

  const instructors = useMemo(() => {
    return edges.map(({ node }) => ({ ...node })).sort((a, b) => a.order - b.order);
  }, [edges]);

  return (
    <div className="page instructors">
      <Header lightMode />
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap" rel="stylesheet" />
        <title>BJJ Gent | Academy</title>
      </Helmet>

      <Container className="instructors-container offset-from-top">
        {
          advancedText.shown ? (
            <div className="info-container">
              <h2>{advancedText.title}</h2>
              {
                advancedText.text.split('\n').map((paragraph) => (
                  <p>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        <div className="instructors-group">
          {
            instructors.filter(({ type }) => type === 'advanced').map(({
              id,
              firstname,
              lastname,
              title,
              type,
              belt_color,
              description,
              photo,
            }) => (
              <Instructor
                key={id}
                name={`${firstname} ${lastname}`}
                title={title}
                belt={belt_color}
                description={description}
                photo={photo}
              />
            ))
          }
        </div>

        {
          basicsText.shown ? (
            <div className="info-container">
              <h2>{basicsText.title}</h2>
              {
                basicsText.text.split('\n').map((paragraph) => (
                  <p>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        <div className="instructors-group">
          {
            instructors.filter(({ type }) => type === 'whitebelt').map(({
              id,
              firstname,
              lastname,
              title,
              type,
              belt_color,
              description,
              photo,
            }) => (
              <Instructor
                key={id}
                name={`${firstname} ${lastname}`}
                title={title}
                belt={belt_color}
                description={description}
                photo={photo}
              />
            ))
          }
        </div>

        {
          kidsText.shown ? (
            <div className="info-container">
              <h2>{kidsText.title}</h2>
              {
                kidsText.text.split('\n').map((paragraph) => (
                  <p>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        <div className="instructors-group">
          {
            instructors.filter(({ type }) => type === 'kids').map(({
              id,
              firstname,
              lastname,
              title,
              type,
              belt_color,
              description,
              photo,
            }) => (
              <Instructor
                key={id}
                name={`${firstname} ${lastname}`}
                title={title}
                belt={belt_color}
                description={description}
                photo={photo}
              />
            ))
          }
        </div>

        {
          noGiText.shown ? (
            <div className="info-container">
              <h2>{noGiText.title}</h2>
              {
                noGiText.text.split('\n').map((paragraph) => (
                  <p>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        <div className="instructors-group">
          {
            instructors.filter(({ type }) => type === 'no-gi').map(({
              id,
              firstname,
              lastname,
              title,
              type,
              belt_color,
              description,
              photo,
            }) => (
              <Instructor
                key={id}
                name={`${firstname} ${lastname}`}
                title={title}
                belt={belt_color}
                description={description}
                photo={photo}
              />
            ))
          }
        </div>

        {
          wrestlingText.shown ? (
            <div className="info-container">
              <h2>{wrestlingText.title}</h2>
              {
                wrestlingText.text.split('\n').map((paragraph) => (
                  <p>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        <div className="instructors-group">
          {
            instructors.filter(({ type }) => type === 'wrestling').map(({
              id,
              firstname,
              lastname,
              title,
              type,
              belt_color,
              description,
              photo,
            }) => (
              <Instructor
                key={id}
                name={`${firstname} ${lastname}`}
                title={title}
                belt={belt_color}
                description={description}
                photo={photo}
              />
            ))
          }
        </div>
      </Container>
      <Footer />
    </div>
  );
}

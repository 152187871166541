import React from 'react';
import { Link } from 'gatsby';
import fbImage from 'src/img/fb.svg';
import instaImage from 'src/img/insta.svg';
import youtubeImage from 'src/img/youtube.svg';
import './Footer.scss';

export default () => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="logo-and-nav">
          <h2 className="emblem">
            Brazilian<br />
            Jiu Jitsu<br />
            Gent
          </h2>
          <nav>
            <ul>
              <li>
                <Link activeClassName="active" to="/">Home</Link>
              </li>
              <li>
                <Link activeClassName="active" to="/programs">Programs</Link>
              </li>
              <li>
                <Link activeClassName="active" to="/instructors">Academy</Link>
              </li>
              <li>
                <Link activeClassName="active" to="/shop">Apparel</Link>
              </li>
              <li>
                <Link activeClassName="active" to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="right-side">
          <div className="socials">
            <h2>Follow us</h2>
            <a href="https://www.facebook.com/BjjGent/"><img src={fbImage} alt="Facebook" /></a>
            <a href="https://www.instagram.com/bjjgent/"><img src={instaImage} alt="Instagram" /></a>
            <a href="https://youtube.com/@bjjgent?si=oauUXdaAAonItRp5/"><img src={youtubeImage} alt="YouTube" /></a>
          </div>
          <div className="disclaimer">
            <Link to="/code-of-conduct">Code of Conduct</Link>
            <p>Victor Braeckmanlaan 180, 9040 Ghent</p>
            <p>&copy; BJJ Gent {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
